/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Text, Button, Image, FullmapCover, SeparateLine, SeparateLineWrap, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Wedding Tereza & Johann"}>
        <Column className="css-825z8 css-1fd5que pb--30" name={"introduction"} style={{"paddingTop":70}} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=3000x_.jpeg);
    }
  
background-position: 50% 26%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"marginTop":10}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--2 fs--48 w--300 subtitle-box--invert ls--001" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"font-style: italic;\">August 5th 2023</span>"}>
              </Subtitle>

              <Title className="title-box ff--3 fs--72 w--900 title-box--invert swpf--uppercase ls--08" style={{"marginTop":12}} content={"Tereza &amp; johann"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="mt--40" name={"about-us"} style={{"marginTop":null,"paddingTop":null,"paddingLeft":null,"marginBottom":0,"paddingBottom":0}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 mb--0 pb--0 pl--40 pr--40" style={{"maxWidth":null,"paddingLeft":null,"marginBottom":null,"paddingRight":null,"paddingBottom":null}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--36 w--900 ls--004" content={"Yes !"}>
              </Title>

              <Text className="text-box fs--18 w--600 lh--16" style={{"marginTop":20}} content={"Two souls who are in tune,<br>Two hearts beating in unison,<br>Today, they want to link their hands,<br>their hearts and their destiny for life.<br><br>Tereza &amp; Johann<br><br>will say yes on August 5, 2023 at the chapel of Saint Jean Baptiste de la Salle school, 62450 Camblain l'abbé.<br>And because love is shared with family and friends:<br>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 fs--24 w--600 lh--16" style={{"marginTop":20}} content={"<span style=\"color: var(--color-custom-1--15);\">You are invited to celebrate with us!</span><br>"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600 lh--16" style={{"marginTop":20}} content={"After the ceremony a drink and a meal will follow at La Chartreuse du Val Saint-Esprit, 62350 Gosnay.<br><br>For everything to be perfect in every detail, lovers need an answer for July 3rd.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"introduction"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} parallax={false} css={css`
      background-position: 51% 45%;
    `}>
          
          <ColumnWrap className="column__flex --center el--3 pt--0" style={{"marginTop":79,"paddingTop":null}} columns={"3"}>
            
            <ColumnWrapper className="pb--20 pl--20 pr--20 pt--20" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Title className="title-box w--300 ls--004" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"font-style: italic;\">When should I arrive?</span><br>"}>
              </Title>

              <Text className="text-box fs--20 w--600" style={{"marginTop":25,"marginBottom":0}} content={"Date and time."}>
              </Text>

              <Button className="btn-box fs--24 w--600" use={"page"} href={"/en#information"} style={{"marginTop":15}} target={null} content={"Schedule"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20 pt--20" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Title className="title-box fs--36 w--300 ls--004" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"font-style: italic;\">Where to stay ?</span>"}>
              </Title>

              <Text className="text-box fs--20 w--600" style={{"marginTop":25,"marginBottom":0}} content={"Looking for a place to stay nearby."}>
              </Text>

              <Button className="btn-box btn-box--center fs--24 w--600" use={"page"} href={"/en#wheretostay"} style={{"marginTop":30}} content={"Hotels"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20 pt--20" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Title className="title-box fs--36 w--300 ls--004" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"font-style: italic;\">Wish to leave us a message?</span><br>"}>
              </Title>

              <Text className="text-box fs--20 w--600" style={{"marginTop":25,"marginBottom":0}} content={"Let us know you are coming!"}>
              </Text>

              <Button className="btn-box fs--24 w--600" href={"/en#picture"} style={{"marginTop":15}} content={"RSVP"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column name={"photogallery"} style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}} layout={"l12"}>
          
          <ColumnWrap className="column__flex --full --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31634/e16447b31dda48f08fefb648b0234bba_e=617x290x941x941_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31634/e16447b31dda48f08fefb648b0234bba_e=617x290x941x941_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/31634/e16447b31dda48f08fefb648b0234bba_e=617x290x941x941_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/31634/e16447b31dda48f08fefb648b0234bba_e=617x290x941x941_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31634/05d4b171eab54fad9341c7b07f87a55a_e=0x4x1989x1989_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31634/05d4b171eab54fad9341c7b07f87a55a_e=0x4x1989x1989_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/31634/05d4b171eab54fad9341c7b07f87a55a_e=0x4x1989x1989_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/31634/05d4b171eab54fad9341c7b07f87a55a_e=0x4x1989x1989_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/31634/05d4b171eab54fad9341c7b07f87a55a_e=0x4x1989x1989_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31634/1bcc8d83a4d645a9af5f57a38197d9cd_e=29x28x1949x1948_s=860x_fo_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31634/1bcc8d83a4d645a9af5f57a38197d9cd_e=29x28x1949x1948_s=350x_fo_.jpg 350w, https://cdn.swbpg.com/t/31634/1bcc8d83a4d645a9af5f57a38197d9cd_e=29x28x1949x1948_s=660x_fo_.jpg 660w, https://cdn.swbpg.com/t/31634/1bcc8d83a4d645a9af5f57a38197d9cd_e=29x28x1949x1948_s=860x_fo_.jpg 860w, https://cdn.swbpg.com/t/31634/1bcc8d83a4d645a9af5f57a38197d9cd_e=29x28x1949x1948_s=1400x_fo_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31634/6c27cb0db4e446a988924f4deef1ecae_e=0x121x1200x1200_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31634/6c27cb0db4e446a988924f4deef1ecae_e=0x121x1200x1200_s=350x_.jpg 350w, https://cdn.swbpg.com/t/31634/6c27cb0db4e446a988924f4deef1ecae_e=0x121x1200x1200_s=660x_.jpg 660w, https://cdn.swbpg.com/t/31634/6c27cb0db4e446a988924f4deef1ecae_e=0x121x1200x1200_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pl--20" name={"information"} style={{"marginTop":0,"paddingTop":9,"paddingLeft":null,"marginBottom":0,"paddingBottom":0,"backgroundColor":"rgba(246, 246, 246, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --full --center el--1 pl--40 pr--40" style={{"marginTop":0,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} columns={"1"}>
            
            <ColumnWrapper className="mb--08">
              
              <Subtitle className="subtitle-box ff--2 fs--36 w--300 ls--004 mt--0 pb--0 pt--0" style={{"marginTop":null,"paddingTop":null,"paddingBottom":null}} content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">Schedule<br></span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"information-2"} style={{"paddingTop":9,"marginBottom":0,"paddingBottom":32,"backgroundColor":"rgba(246, 246, 246, 1)"}} layout={"l10"}>
          
          <ColumnWrap className="column__flex el--2 pl--40 pr--40" style={{"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} columns={"2"}>
            
            <ColumnWrapper className="column__flex--top --center" style={{"marginBottom":0,"paddingBottom":273}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/b6695e0a626a44bb9c3fa639f8535105.svg"} svg={false} style={{"maxWidth":140}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box ff--2 fs--36 w--300" style={{"marginTop":0,"marginBottom":0}} content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">Religious Ceremony</span>"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600" style={{"marginTop":8}} content={"10h - 11h30<br>Ecole Saint Jean Baptiste de la Salle<br><br>"}>
              </Text>

              <FullmapCover zoom={""} place={"Camblain l'abbé Ecole saint jean"} style={{"marginBottom":9,"paddingBottom":0}}>
              </FullmapCover>

            </ColumnWrapper>

            <ColumnWrapper className="column__flex--top --center" style={{"marginBottom":3,"paddingBottom":273}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/d996d9080be947789a2ec065ff964959.svg"} svg={false} style={{"maxWidth":140}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box ff--2 fs--36 w--300" style={{"marginTop":0,"marginBottom":0}} content={"<span style=\"color: rgb(0, 0, 0); font-style: italic;\">Wines &amp; Reception</span><br>"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600" style={{"marginTop":8}} content={"12h - 20h<br>La Chartreuse du Val Saint-Esprit<br><br>"}>
              </Text>

              <FullmapCover zoom={"10"} place={"Gosnay La chartreuse"} style={{"marginTop":0,"paddingTop":0,"marginBottom":0}}>
              </FullmapCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pl--20" name={"wheretostay"} style={{"marginTop":0,"paddingTop":9,"paddingLeft":null,"marginBottom":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --full --center el--1 pl--40 pr--40" style={{"marginTop":0,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0,"backgroundColor":"var(--white)"}} columns={"1"}>
            
            <ColumnWrapper className="mb--08">
              
              <Subtitle className="subtitle-box ff--2 fs--36 w--300 ls--004 mt--0 pb--0 pt--0" style={{"marginTop":null,"paddingTop":null,"paddingBottom":null}} content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">Where to Stay<br></span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"hotels"} style={{"paddingTop":65,"paddingBottom":25}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"La Chartreuse"}>
              </Title>

              <Text className="text-box" content={"****<br>On site<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.lachartreuse.com/"} href={"https://www.lachartreuse.com/"} content={"Link"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"La Metairie"}>
              </Title>

              <Text className="text-box" content={"***<br>On site<br>"}>
              </Text>

              <Button className="btn-box" url={"http://www.hotel-lametairie.com/"} href={"http://www.hotel-lametairie.com/"} content={"Link"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Text className="text-box" content={"Mention the wedding during your reservation to get a discount.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pl--20" name={"information"} style={{"marginTop":0,"paddingTop":9,"paddingLeft":null,"marginBottom":0,"paddingBottom":0,"backgroundColor":"#f6f6f6"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --full --center el--1 pl--40 pr--40" style={{"marginTop":0,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0,"backgroundColor":"rgba(246,246,246,1)"}} columns={"1"}>
            
            <ColumnWrapper className="mb--08">
              
              <Subtitle className="subtitle-box ff--2 fs--36 w--300 ls--004 mt--0 pb--0 pt--0" style={{"marginTop":null,"paddingTop":null,"paddingBottom":null}} content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">Transportation<br></span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"0uv4rbr580il"} style={{"marginTop":0,"paddingTop":35,"paddingBottom":43,"backgroundColor":"#f6f6f6"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"Airport Lille-Lesquin"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"About 60 km away, connection possible with Arras and Béthune from Lille Train station."}>
              </Text>

              <Subtitle className="subtitle-box" content={"Airport Paris&nbsp;Charles de Gaulle"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"The airport has direct train connection with Lille and Arras"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Train Station Arras"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"About 30 km from the Reception, 16 km from the Ceremony"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"izj0twp9e5"} layout={"l4"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-825z8 css-1sq4df2 --parallax pl--20 pt--30" name={"picture"} style={{"marginTop":0,"paddingLeft":null,"marginBottom":0,"paddingBottom":50}} layout={"l1"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31634/13283eb8ec94477fbc7064e323bd8cdc_ove=ff5000x8__s=3000x_.jpeg);
    }
  
background-position: 51% 25%;
    `}>
          
          <ColumnWrap className="column__flex --full --center el--1 mb--0 pl--40 pr--40" style={{"paddingLeft":null,"marginBottom":null,"paddingRight":null,"paddingBottom":20}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--2 fs--72 w--300 title-box--invert ls--004" content={"<span style=\"font-style: italic;\">We look forward to seeing you!</span><br>"}>
              </Title>

              <Text className="text-box fs--20 w--600 text-box--invert lh--16" style={{"maxWidth":700,"marginTop":20}} content={"RSVP before July 3rd 2023"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"contact"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style2 --right" style={{"maxWidth":740,"marginBottom":25}} action={"/contact"} fields={[{"name":"Will attend ?","type":"select","options":["Will attend","Will not attend"],"required":true},{"name":"Adults","type":"select","options":["0","1","2","3","4"],"placeholder":"Number of adults"},{"name":"Kids","type":"select","options":["0","1","2","3","4","5"],"required":true,"placeholder":"Number of kids"},{"name":"Name","type":"text","required":true},{"name":"E-mail","type":"email","required":true,"placeholder":"Votre addresse e-mail"},{"name":"Phone","type":"text","placeholder":"Numéro de téléphone"},{"name":"Message","type":"textarea","required":false,"placeholder":"Enter your message"},{"name":"Send","type":"submit","align":"center"}]} layout={"l3"}>
              </ContactForm>

              <Text className="text-box" content={"Please tell us about your dietary restrictions (Allergies, vegetarian, etc.)<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"footer"} style={{"backgroundColor":"var(--color-variable-1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--20 w--600" content={"Tereza &amp; Johann<br>5 Août 2023<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}